export default {
  getAuthentication(state) {
    return state.authentication
  },
  getAuthorizationHeaders(state) {
    if (state.authentication) {
      return {
        Authorization: state.authentication.tokenType + ' ' + state.authentication.accessToken
      }
    }
    return {}
  },
  getCurrentCustomer(state) {
    return state.customer
  },
  sufficientCredit: state => totalprice => {
    return state.customer && state.customer.creditAmount >= totalprice
  },
  canOrderOnAccount: state => {
    return state.customer && state.customer.allowedToPayOnAccount
  },
  creditAmount(state) {
    return state.customer && state.customer.creditAmount
  },
  isUserDataComplete(state) {
    return state.customer && state.customer.complete
  },
  getPreferences(state) {
    return state.preferences
  },
  getAddressLists(state) {
    return state.addressLists
  },
  getAddressListById: state => id => {
    return state.addressLists.find(addressList => addressList.id === id)
  },
  getMyTemplates(state) {
    return state.myTemplates
  },
  getMonkeyTemplates(state) {
    return state.monkeyTemplates
  },
  templateTitleExists: state => (title, templateId) => {
    return state.myTemplates.length === 0 ? false : state.myTemplates.find(myTemplate => myTemplate.title === title && myTemplate.id !== templateId)
  },
  getActiveOption(state) {
    return state.activeOption
  },
  isAwareOfAddresslist(state) {
    return state.awareOfAddresslist
  },
  isDesignerAccount(state) {
    return state.customer && state.customer.isDesignAccount
  }
}
