export default {
  setDialogVisibility({ commit }, visible) {
    commit('SET_DIALOG_VISIBILITY', visible)
  },
  setDialogTitle({ commit }, title) {
    commit('SET_DIALOG_TITLE', title)
  },
  setDialogComponent({ commit }, component) {
    commit('SET_DIALOG_COMPONENT', component)
  },
  setDialogDescription({ commit }, description) {
    commit('SET_DIALOG_DESCRIPTION', description)
  },
  setDialogCallbacks({ commit }, callbacks) {
    commit('SET_DIALOG_CALLBACKS', callbacks)
  },
  setDialogData({ commit }, data) {
    commit('SET_DIALOG_DATA', data)
  },
  toggleDialogVisibility({ state, commit, dispatch }) {
    dispatch('setDialogVisibility', state.visibility)
  },
  showDialog({ commit, dispatch }, object) {
    if (object.hasOwnProperty('title') && object.hasOwnProperty('component')) {
      dispatch('setDialogCallbacks', { success: object.success, cancel: object.cancel })
      dispatch('setDialogTitle', object.title)
      dispatch('setDialogDescription', object.description)
      dispatch('setDialogComponent', object.component)
      dispatch('setDialogData', object.data)
      dispatch('setDialogVisibility', true)
    }
  },
  hideDialog({ commit, dispatch }) {
    dispatch('setDialogTitle', undefined)
    dispatch('setDialogDescription', undefined)
    dispatch('setDialogComponent', undefined)
    dispatch('setDialogData', undefined)
    dispatch('setDialogVisibility', false)
  }
}
