export default {
  updateStep({ commit }, step) {
    commit('UPDATE_STEP', step)
  },
  updateOrder({ commit }, order) {
    commit('UPDATE_ORDER', order)
  },
  updateTemplate({ commit }, template) {
    commit('UPDATE_TEMPLATE', template)
  },
  updateRecipientData({ getters, commit }, recipientData) {
    commit('UPDATE_RECIPIENT_DATA', recipientData)
  },
  updateFabricJSON({ getters, commit }, { sideId, newJSON }) {
    const sideIndex = getters.getSideIndex(sideId)
    commit('UPDATE_FABRICJSON', {
      sideIndex,
      newJSON
    })
  },
  toggleSidebar({ commit }, newData) {
    commit('TOGGLE_SIDEBAR', newData)
  },
  setAvailableDeliveryDates({ commit }, dates) {
    commit('SET_AVAILABLE_DELIVERY_DATES', dates)
  },
  setDeliveryDate({ commit }, date) {
    commit('SET_DELIVERY_DATE', date)
  },
  setProducts({ commit }, products) {
    commit('SET_PRODUCTS', products)
  },
  setPaperTypes({ commit }, paperTypes) {
    commit('SET_PAPERTYPES', paperTypes)
  },
  setPaperCoatings({ commit }, paperCoatings) {
    commit('SET_PAPERCOATINGS', paperCoatings)
  },
  setPaperTypeId({ commit }, paperTypeId) {
    commit('SET_PAPERTYPE_ID', paperTypeId)
  },
  setPaperCoatingId({ commit }, paperCoatingId) {
    commit('SET_PAPERCOATING_ID', paperCoatingId)
  },
  setUserTemplateTitle({ commit }, title) {
    commit('SET_USER_TEMPLATE_TITLE', title)
  },
  setReference({ commit }, reference) {
    commit('SET_REFERENCE', reference)
  },
  clearOrder({ commit }) {
    commit('UPDATE_ORDER', undefined)
  }
}
