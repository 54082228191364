import state from './generator/state'
import mutations from './generator/mutations'
import getters from './generator/getters'
import actions from './generator/actions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
