import state from './dialog/state'
import mutations from './dialog/mutations'
import getters from './dialog/getters'
import actions from './dialog/actions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
