export default {
  getCardSides(state) {
    return state.cardSides
  },
  getActiveSideId(state) {
    return state.activeSideId
  },
  getActiveObjectIndex(state) {
    return state.activeObjectIndex
  },
  isGridVisible(state) {
    return state.grid
  },
  isSnapEnabled(state) {
    return state.snap
  }
}
