export default {
  order: undefined,
  step: 1,
  showSidebar: true,
  fonts: [
    'Exo',
    'Fahkwang',
    'Grandstander',
    'JetBrains Mono',
    'Josefin Sans',
    'Josefin Slab',
    'K2D',
    'Lato',
    'Lobster Two',
    'Mali',
    'Merriweather',
    'Mulish',
    'Open Sans',
    'Petrona',
    'Playfair Display',
    'Vollkorn',
    'Work Sans'
  ],
  availableDeliveryDates: [],
  products: [],
  paperTypes: [],
  paperCoatings: [],
  reference: ''
}
