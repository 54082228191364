export default {
  setAuthentication({ commit }, authentication) {
    commit('UPDATE_AUTHENTICATION', authentication)
  },
  clearAuthentication({ commit }) {
    commit('UPDATE_AUTHENTICATION', undefined)
  },
  setCustomer({ commit }, customer) {
    commit('UPDATE_CUSTOMER', customer)
  },
  clearCustomer({ commit }) {
    commit('UPDATE_CUSTOMER', undefined)
  },
  setPreferences({ commit }, preferences) {
    commit('UPDATE_PREFERENCES', preferences)
  },
  clearPreferences({ commit }) {
    commit('UPDATE_PREFERENCES', {
      predefinedColors: '#000000,#FFFFFF'
    })
  },
  addPredefinedColor({ commit }, color) {
    commit('ADD_PREDEFINED_COLOR', color)
  },
  setAddressLists({ commit }, addressLists) {
    commit('UPDATE_ADDRESSLISTS', addressLists)
  },
  addAddressList({ commit }, addressList) {
    commit('ADD_ADDRESSLIST', addressList)
  },
  setMyTemplates({ commit }, templates) {
    commit('UPDATE_MY_TEMPLATES', templates)
  },
  setMonkeyTemplates({ commit }, templates) {
    commit('UPDATE_MONKEY_TEMPLATES', templates)
  },
  setActiveOption({ commit }, value) {
    commit('UPDATE_ACTIVE_OPTION', value)
  },
  setAwareOfAddresslist({ commit }, value) {
    commit('SET_AWARE_OF_ADDRESSLIST', value)
  }
}
