export default {
  isLoading(state) {
    return state.loading
  },
  getDialogVisibility(state) {
    return state.dialogVisibility
  },
  getDialogTitle(state) {
    return state.dialogTitle
  },
  getDialogDescription(state) {
    return state.dialogDescription
  },
  getDialogComponent(state) {
    return state.dialogComponent
  },
  getDialogCallbacks(state) {
    return state.dialogCallbacks
  },
  getDialogData(state) {
    return state.dialogData
  }
}
