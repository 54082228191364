export default {
  SET_LOADING(state, loadingValue) {
    state.loading = loadingValue
  },
  SET_DIALOG_VISIBILITY(state, visible) {
    state.dialogVisibility = visible
  },
  SET_DIALOG_TITLE(state, title) {
    state.dialogTitle = title
  },
  SET_DIALOG_DESCRIPTION(state, description) {
    state.dialogDescription = description
  },
  SET_DIALOG_COMPONENT(state, component) {
    state.dialogComponent = component
  },
  SET_DIALOG_CALLBACKS(state, callbacks) {
    state.dialogCallbacks = callbacks
  },
  SET_DIALOG_DATA(state, data) {
    state.dialogData = data
  }
}
