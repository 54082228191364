export default {
  UPDATE_AUTHENTICATION(state, authentication) {
    state.authentication = authentication
  },
  UPDATE_CUSTOMER(state, customer) {
    state.customer = customer
  },
  UPDATE_PREFERENCES(state, preferences) {
    state.preferences = preferences
  },
  ADD_PREDEFINED_COLOR(state, color) {
    let colors = state.preferences.predefinedColors ? state.preferences.predefinedColors.split(',') : []
    if (colors.indexOf(color) !== -1) {
      colors.splice(colors.indexOf(color), 1)
    }
    colors.unshift(color)
    if (colors.length > 20) {
      colors = colors.slice(0, 20)
    }
    state.preferences.predefinedColors = [...new Set(colors)].join(',')
  },
  UPDATE_ADDRESSLISTS(state, addresslists) {
    state.addressLists = addresslists
  },
  ADD_ADDRESSLIST(state, addressList) {
    const index = state.addressLists.find(item => item.id === addressList.id)
    if (index) {
      state.addressLists.splice(index, 1)
    }
    state.addressLists.push(addressList)
  },
  UPDATE_MY_TEMPLATES(state, templates) {
    state.myTemplates = templates
  },
  UPDATE_MONKEY_TEMPLATES(state, templates) {
    state.monkeyTemplates = templates
  },
  UPDATE_ACTIVE_OPTION(state, value) {
    state.activeOption = value
  },
  SET_AWARE_OF_ADDRESSLIST(state, value) {
    state.awareOfAddresslist = value
  }
}
