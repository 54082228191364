const getters = {
  getSideIndex: state => id => {
    return state.order.orderLines[0].template.cardSides.findIndex(cardSide => {
      return cardSide.id === id
    })
  },
  getCurrentStep(state) {
    return state.step
  },
  getCardSides(state) {
    const template = getters.getTemplate(state)
    if (!template) {
      return []
    }
    return template.cardSides
  },
  getCardSideByName: state => side => {
    const cardSide = state.order.orderLines[0].template.cardSides.find(cardSide => cardSide.side === side)
    if (cardSide !== undefined) {
      return cardSide
    }
  },
  hasCardSides(state) {
    const template = getters.getTemplate(state)
    if (!template) {
      return false
    }
    return template.cardSides && template.cardSides.length > 0
  },
  showSidebar(state) {
    return state.showSidebar
  },
  getFonts(state) {
    return state.fonts
  },
  getTags(state) {
    const orderLine = getters.getOrderLine(state)
    if (!orderLine || !orderLine.recipientData) {
      return []
    }
    return [
      ...(orderLine.recipientData.extraColumns ? orderLine.recipientData.extraColumns.reverse().map(c => ({ label: c, value: c })) : []),
      ...orderLine.recipientData.filledAddressColumns.map(c => ({ label: `configurator.addressfields.fieldname.${c}`, value: c }))
    ]
  },
  hasTags(state) {
    const orderLine = getters.getOrderLine(state)
    return orderLine.recipientData && orderLine.recipientData.recipientCount && orderLine.recipientData.recipientCount > 0
  },
  hasOrder(state) {
    return state.order !== undefined
  },
  hasTemplate(state) {
    return getters.getTemplate(state) !== undefined
  },
  getOrderLine(state) {
    if (state.order && state.order.orderLines && state.order.orderLines.length >= 1) {
      return state.order.orderLines[0]
    }
    return undefined
  },
  getTemplate(state) {
    const orderLine = getters.getOrderLine(state)
    if (!orderLine || !orderLine.template) {
      return undefined
    }
    return orderLine.template
  },
  getAddressList(state) {
    const orderLine = getters.getOrderLine(state)
    if (!orderLine || !orderLine.recipientData) {
      return undefined
    }
    const userAddressList = getters.getUserAddressList(state)
    return userAddressList ?? orderLine.recipientData
  },
  getUserAddressList(state) {
    return state.order ? state.order.userRecipientData : null
  },
  getUserTemplate(state) {
    return state.order ? state.order.userTemplate : null
  },
  getAvailableDeliveryDates(state) {
    return state.availableDeliveryDates
  },
  isCustomSize(state) {
    const template = getters.getTemplate(state)
    if (!template) {
      return false
    }
    return template.custom
  },
  getProducts(state) {
    return state.products
  },
  getDefaultProduct(state) {
    const product = state.products.find(product => product.defaultChoice)
    if (product !== undefined) {
      return product
    }
  },
  getPaperTypes(state) {
    return state.paperTypes
  },
  getDefaultPaperType(state) {
    const paperType = state.paperTypes.find(paperType => paperType.defaultChoice)
    if (paperType !== undefined) {
      return paperType
    }
  },
  getPaperCoatingsFromPaperType: state => id => {
    const paperType = state.paperTypes.find(paperType => paperType.id === id)
    if (paperType !== undefined) {
      return paperType.paperCoatings
    }
  },
  getDefaultPaperCoatingFromPaperType: (state, getters) => id => {
    const paperCoatings = getters.getPaperCoatingsFromPaperType(id)
    const defaultPaperCoating = paperCoatings.find(paperCoating => paperCoating.defaultChoice)
    if (defaultPaperCoating !== undefined) {
      return defaultPaperCoating
    }
  },
  getPaperCoatings(state) {
    return state.paperCoatings
  },
  getPaperTypeId(state) {
    const orderLine = getters.getOrderLine(state)
    if (!orderLine || !orderLine.template) {
      return undefined
    }
    return orderLine.template.paperTypeId
  },
  getPaperTypeTitle(state) {
    const template = getters.getTemplate(state)
    if (template) {
      const index = state.paperTypes.findIndex(paperType => {
        return paperType.id === template.paperTypeId
      })
      const paperType = state.paperTypes[index]
      if (paperType === undefined) {
        return false
      }
      return paperType.title
    } else {
      return false
    }
  },
  getPaperTypePrice(state) {
    const template = getters.getTemplate(state)
    const large = template ? template.large : false
    const index = state.paperTypes.findIndex(paperType => {
      return paperType.id === template.paperTypeId
    })
    const paperType = state.paperTypes[index]
    if (paperType === undefined) {
      return false
    }
    if (large) {
      return paperType.priceLarge
    } else {
      return paperType.priceDefault
    }
  },
  getPaperCoatingTitle(state) {
    const template = getters.getTemplate(state)
    const index = state.paperCoatings.findIndex(paperCoating => {
      return paperCoating.id === template.paperCoatingId
    })
    const paperCoating = state.paperCoatings[index]
    if (paperCoating === undefined) {
      return false
    }
    return paperCoating.title
  },
  getPaperCoatingPrice(state) {
    const template = getters.getTemplate(state)
    const large = template ? template.large : false
    const index = state.paperCoatings.findIndex(paperCoating => {
      return paperCoating.id === template.paperCoatingId
    })
    const paperCoating = state.paperCoatings[index]
    if (paperCoating === undefined) {
      return false
    }
    if (large) {
      return paperCoating.priceLarge
    } else {
      return paperCoating.priceDefault
    }
  },
  getDeliveryDate(state) {
    if (state.order && state.order.orderLines && state.order.orderLines.length >= 1 && state.order.deliveryDate) {
      const dateObj = new Date(state.order.deliveryDate)
      return dateObj.toLocaleDateString().replace(/\//g, '-')
    }
    return undefined
  },
  getTotalPriceEx(state) {
    if (state.order && state.order.orderLines && state.order.orderLines.length >= 1) {
      return state.order.totalPriceEx
    }
    return 0
  },
  getTotalVat(state) {
    if (state.order && state.order.orderLines && state.order.orderLines.length >= 1) {
      return state.order.vatAmount.reduce((a, b) => a + b.amount, 0)
    }
    return 0
  },
  getTotalPriceInc(state) {
    if (state.order && state.order.orderLines && state.order.orderLines.length >= 1) {
      return state.order.totalPriceInc
    }
    return undefined
  },
  getPriceProduct(state) {
    const orderLine = getters.getOrderLine(state)
    if (!orderLine || !orderLine.template) {
      return 0
    }
    return orderLine.priceProduct
  },
  getSubTotalPrice(state) {
    const orderLine = getters.getOrderLine(state)
    if (!orderLine || !orderLine.template) {
      return 0
    }
    return orderLine.subTotalPrice
  },
  getWeight(state) {
    const orderLine = getters.getOrderLine(state)
    if (!orderLine || !orderLine.template) {
      return undefined
    }
    return orderLine.weight
  },
  getOverallStatus(state) {
    return state.order && state.order.overallStatus ? state.order.overallStatus : null
  },
  getOrder(state) {
    return state.order
  },
  getProductOptions(state) {
    const orderLine = getters.getOrderLine(state)
    if (!orderLine || !orderLine.orderLineProductOptions || orderLine.orderLineProductOptions.length === 0) {
      return []
    }
    return orderLine.orderLineProductOptions
  },
  getReference(state) {
    return state.reference
  },
  templateIsResized(state) {
    const orderLine = getters.getOrderLine(state)
    if (!orderLine || !orderLine.template) {
      return false
    }
    return (
      getters.hasTemplate(state) &&
      orderLine.size &&
      orderLine.template.size &&
      (orderLine.size.width !== orderLine.template.size.width || orderLine.size.height !== orderLine.template.size.height)
    )
  },
  resizeFactor(state) {
    const orderLine = getters.getOrderLine(state)
    if (!orderLine || !orderLine.template) {
      return false
    }
    return getters.templateIsResized(state)
      ? Math.min(orderLine.size.width / orderLine.template.size.width, orderLine.size.height / orderLine.template.size.height)
      : 1
  },
  getReturnAddress(state) {
    if (state.order && state.order.returnAddress) {
      return state.order.returnAddress
    }
    return undefined
  }
}

export default getters
