<template>
  <div v-if="$config" class="container u-bgcolor--secondary">
    <div v-if="!isMobileDevice">
      <c-header />
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
      <div v-if="$route.meta.stepsVisible && false" class="u-bgcolor--secondary">
        <div class="u-mh u-pv">
          <c-steps-nav />
        </div>
      </div>
      <c-footer />
      <c-dialog />
    </div>
    <c-mobile :is-mobile="isMobileDevice" />
    <google-tag-manager :gtm-id="$configValue('GTM_ID')" />
  </div>
</template>

<script>
import store from '@js/store/store'
import axiosHelperMixin from '@js/mixins/m-axios-helper.vue'
import { EventBus } from '@js/event-bus'
import TagManager from '@c/c-google-tag-manager.vue'
import { isMobile } from 'mobile-device-detect'
import { SHOW_ERROR_MESSAGE, ERROR_DURATION } from '@js/constants.js'

const Header = () => import('@c/c-header.vue')
const StepsNav = () => import('@c/c-steps-nav.vue')
const FooterWrapper = () => import('@c/c-footer.vue')
const Mobile = () => import('@c/c-mobile.vue')
const Dialog = () => import('@c/c-dialog.vue')

export default {
  store,
  components: {
    'c-header': Header,
    'c-steps-nav': StepsNav,
    'c-footer': FooterWrapper,
    'c-dialog': Dialog,
    'c-mobile': Mobile,
    'google-tag-manager': TagManager
  },
  mixins: [axiosHelperMixin],
  data() {
    return {
      step: '',
      requireOrder: false,
      orderLoaded: false,
      isMobileDevice: isMobile
    }
  },
  computed: {
    getCurrentCustomer() {
      return this.$store.getters['account/getCurrentCustomer']
    },
    getOrderStatus() {
      return this.$store.getters['basket/getOverallStatus']
    }
  },
  watch: {
    /* eslint-disable */
    $route(to, from) {
      if (this.orderLoaded === true) {
        this.checkToResetOrder()
      }
      if (this.$route.query.p === undefined) {
        // skip if it is a direct url
        this.$store.dispatch('dialog/hideDialog')
        if (this.$route.meta.stepsVisible) {
          if (this.orderLoaded === true) {
            if (!this.$store.getters['basket/getOrderLine'] || this.$store.getters['basket/getOverallStatus'] !== 'INPROGRESS') {
              this.$router.push('/').catch(() => {})
            }
          } else {
            this.requireOrder = true
          }
        }
        if (this.$route.meta.step !== undefined) {
          this.$store.dispatch('basket/updateStep', this.$route.meta.step)
          this.step = this.$route.meta.step
          localStorage.step = this.step
        }
        if (from.meta.autosave) {
          EventBus.$emit('save-sides', error => {
            if (error) {
              this.$router.push(from.path).catch(() => {})
            }
          })
        }
      }
    }
    /* eslint-enable */
  },
  created() {
    this.loadCurrentSession()
  },
  mounted() {
    this.axiosInstance()
      .get('/api/tooltips')
      .then(response => {
        if (response.status === 200) {
          this.$store.dispatch('general/setTooltips', response.data)
        }
      })
      .catch(() => {
        // tooltips cant be loaded
      })
    this.axiosInstance()
      .get('/api/config')
      .then(response => {
        if (response.status === 200) {
          this.$store.dispatch('general/setConfig', response.data)
        }
      })
      .catch(error => {
        if (SHOW_ERROR_MESSAGE) {
          this.$notify.error({
            title: this.$t('errors.title'),
            message: this.$t(error.message),
            duration: ERROR_DURATION
          })
        }
      })
    if (localStorage.step) {
      this.step = localStorage.step
    }
    setInterval(this.updateToken, 10 * 60 * 1000) //10 mins
  },
  beforeDestroy() {
    clearInterval(this.updateToken)
  },
  methods: {
    checkToResetOrder() {
      if (this.$route.name !== 'PaymentReturn' && this.getOrderStatus !== 'INPROGRESS') {
        this.axiosInstance()
          .get('/api/order/reset')
          .then(() => {
            this.$store.dispatch('basket/clearOrder').then(() => {
              this.$router.push('/stap-1').catch(() => {})
            })
          })
          .catch(() => {})
      }
    },
    updateToken() {
      this.axiosInstance().get('/api/customer/token')
    },
    loadCurrentSession() {
      this.axiosInstance()
        .get('/api/customer/current')
        .then(response => {
          if (response.status === 200) {
            this.$store.dispatch('account/setCustomer', response.data)
            this.axiosInstance()
              .get('/api/preferences')
              .then(response => {
                if (response.status === 200) {
                  this.$store.dispatch('account/setPreferences', response.data)
                }
              })
          }
        })
        .catch(() => {
          this.$store.dispatch('account/clearCustomer')
        })
      this.axiosInstance()
        .get('/api/order/current')
        .then(response => {
          if (response.status === 200) {
            this.$store.dispatch('basket/updateOrder', response.data)
          }
          this.orderLoaded = true
        })
        .catch(() => {
          this.orderLoaded = true
          if (this.requireOrder) {
            this.$router.push('/').catch(() => {})
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.o-logo,
.o-menu {
  z-index: 3;
}
.o-menu {
  font-weight: bold;
  z-index: 11;
  .menu-item {
    cursor: pointer;
    margin-left: 10px;
    .router-link-active {
      color: $font__color--invert;
      border-bottom: solid 2px $color--secondary;
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 250ms;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.back-to-step {
  bottom: 20px;
  position: fixed;
  width: 220px;
  right: calc(50% - 110px);
  z-index: 10;
  box-shadow: 0 0 20px -5px #000;
}

.slide-fade-enter-active {
  transition: all 500ms ease;
}

.slide-fade-leave-active {
  transition: all 100ms cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateX(100px);
}
</style>
