export default {
  UPDATE_ORDER(state, order) {
    state.order = order
  },
  UPDATE_TEMPLATE(state, template) {
    if (state.order && state.order.orderLines && state.order.orderLines[0]) {
      state.order.orderLines[0].template = template
    }
  },
  UPDATE_RECIPIENT_DATA(state, recipientData) {
    if (state.order && state.order.orderLines && state.order.orderLines[0]) {
      state.order.orderLines[0].recipientData = recipientData
      state.order = JSON.parse(JSON.stringify(state.order))
    }
  },
  UPDATE_STEP(state, step) {
    state.step = step
  },
  UPDATE_FABRICJSON(state, { sideIndex, newJSON }) {
    state.order.orderLines[0].template.cardSides[sideIndex].fabricJSON = newJSON
  },
  TOGGLE_SIDEBAR(state, newData) {
    state.showSidebar = newData
  },
  SET_AVAILABLE_DELIVERY_DATES(state, dates) {
    state.availableDeliveryDates = dates
  },
  SET_DELIVERY_DATE(state, date) {
    const parts = date.split('-')
    const Ymd = parts[2] + '-' + parts[1] + '-' + parts[0]
    const timestamp = Date.parse(Ymd)
    state.order.deliveryDate = timestamp
  },
  SET_PRODUCTS(state, products) {
    state.products = products
  },
  SET_PAPERTYPES(state, paperTypes) {
    state.paperTypes = paperTypes
  },
  SET_PAPERCOATINGS(state, paperCoatings) {
    state.paperCoatings = paperCoatings
  },
  SET_PAPERTYPE_ID(state, paperTypeId) {
    state.order.orderLines[0].template.paperTypeId = paperTypeId
  },
  SET_PAPERCOATING_ID(state, paperCoatingId) {
    state.order.orderLines[0].template.paperCoatingId = paperCoatingId
  },
  SET_USER_TEMPLATE_TITLE(state, title) {
    if (!state.order.userTemplate) {
      state.order.userTemplate = {}
    }
    state.order.userTemplate.title = title
  },
  SET_REFERENCE(state, reference) {
    state.reference = reference
  }
}
