export default {
  getCreditMaxAmount(state) {
    return state.config['credit.max.amount']
  },
  isSaving(state) {
    return state.saving
  },
  getTooltips(state) {
    return state.tooltips
  },
  getTooltipByName: state => name => {
    const tooltip = state.tooltips.find(tooltip => tooltip.code === name)
    return tooltip ? tooltip.text : null
  },
  getActiveOption(state) {
    return state.activeOption
  }
}
