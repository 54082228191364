var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$config
    ? _c(
        "div",
        { staticClass: "container u-bgcolor--secondary" },
        [
          !_vm.isMobileDevice
            ? _c(
                "div",
                [
                  _c("c-header"),
                  _vm._v(" "),
                  _c(
                    "transition",
                    { attrs: { name: "fade", mode: "out-in" } },
                    [_c("router-view")],
                    1
                  ),
                  _vm._v(" "),
                  _vm.$route.meta.stepsVisible && false
                    ? _c("div", { staticClass: "u-bgcolor--secondary" }, [
                        _c(
                          "div",
                          { staticClass: "u-mh u-pv" },
                          [_c("c-steps-nav")],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("c-footer"),
                  _vm._v(" "),
                  _c("c-dialog")
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("c-mobile", { attrs: { "is-mobile": _vm.isMobileDevice } }),
          _vm._v(" "),
          _c("google-tag-manager", {
            attrs: { "gtm-id": _vm.$configValue("GTM_ID") }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }