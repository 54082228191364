/* istanbul ignore file */
// 1. Define route components.
const PageNotFound = () => import('@p/404.vue')
const Step1 = () => import('@p/Step1.vue')
const Step2 = () => import('@p/Step2.vue')
const Step3 = () => import('@p/Step3.vue')
const Step4 = () => import('@p/Step4.vue')
const Step5 = () => import('@p/Step5.vue')

const PaymentReturn = () => import('@p/PaymentReturn.vue')
const AccountActivate = () => import('@p/account/Activate.vue')
const ForgotPassword = () => import('@p/account/ForgotPassword.vue')
const ResetPassword = () => import('@p/account/ResetPassword.vue')
const Account = () => import('@p/Account.vue')
const LoadTemplate = () => import('@p/LoadTemplate.vue')

const MonkeyTemplates = () => import('@p/content/MonkeyTemplates.vue')
const ForWho = () => import('@p/content/ForWho.vue')

// 2. Define some routes
const routes = [
  {
    path: '/',
    redirect: {
      name: 'Step1'
    }
  },
  { path: '*', component: PageNotFound },
  {
    name: 'Activate Account',
    path: '/activate-account',
    component: AccountActivate,
    meta: {
      stepsVisible: false,
      mobileOptimized: false
    }
  },
  {
    name: 'Forgot Password',
    path: '/forgot-password',
    component: ForgotPassword,
    meta: {
      stepsVisible: false,
      mobileOptimized: false
    }
  },
  {
    name: 'Reset Password',
    path: '/reset-password',
    component: ResetPassword,
    meta: {
      stepsVisible: false,
      mobileOptimized: false
    }
  },
  {
    name: 'Account',
    path: '/account',
    component: Account,
    meta: {
      userRequired: true,
      stepsVisible: false,
      mobileOptimized: false
    }
  },
  {
    name: 'Step1',
    path: '/stap-1',
    meta: {
      step: 1,
      previousUrl: false,
      nextUrl: '/stap-2',
      stepsVisible: true,
      orderRequired: true,
      autosave: true,
      mobileOptimized: false
    },
    component: Step1
  },
  {
    name: 'Step2',
    path: '/stap-2',
    meta: {
      step: 2,
      previousUrl: '/stap-1',
      nextUrl: '/stap-3',
      stepsVisible: true,
      orderRequired: true,
      autosave: true,
      mobileOptimized: false
    },
    component: Step2
  },
  {
    name: 'Step3',
    path: '/stap-3',
    meta: {
      step: 3,
      previousUrl: '/stap-2',
      nextUrl: '/stap-4',
      stepsVisible: true,
      orderRequired: true,
      mobileOptimized: false
    },
    component: Step3
  },
  {
    name: 'Step-4',
    path: '/stap-4',
    meta: {
      step: 4,
      previousUrl: '/stap-3',
      nextUrl: '/stap-5',
      stepsVisible: true,
      orderRequired: true,
      mobileOptimized: false
    },
    component: Step4
  },
  {
    name: 'Stap-5',
    path: '/stap-5',
    meta: {
      step: 5,
      previousUrl: '/stap-4',
      nextUrl: false,
      stepsVisible: true,
      orderRequired: true,
      mobileOptimized: false
    },
    component: Step5
  },
  {
    name: 'PaymentReturn',
    path: '/gereed',
    meta: {
      stepsVisible: false,
      orderRequired: true,
      mobileOptimized: false
    },
    component: PaymentReturn
  },
  {
    name: 'ForWho',
    path: '/voor-wie',
    meta: {
      stepsVisible: false,
      mobileOptimized: false
    },
    component: ForWho
  },
  {
    name: 'MonkeyTemplates',
    path: '/monkey-ontwerpen',
    meta: {
      stepsVisible: false,
      orderRequired: false,
      mobileOptimized: false
    },
    component: MonkeyTemplates
  },
  {
    name: 'MonkeyTemplates',
    path: '/monkey-ontwerpen/:category',
    meta: {
      stepsVisible: false,
      orderRequired: false,
      mobileOptimized: false
    },
    component: MonkeyTemplates
  },
  {
    name: 'LoadTemplate',
    path: '/load-template/:templateId',
    meta: {
      stepsVisible: false,
      mobileOptimized: false
    },
    component: LoadTemplate
  }
]

export default routes
