export default {
  setConfig({ commit }, config) {
    commit('UPDATE_CONFIG', config)
  },
  setTooltips({ commit }, tooltips) {
    commit('UPDATE_TOOLTIPS', tooltips)
  },
  setSaving({ commit }, value) {
    commit('UPDATE_SAVING_STATE', value)
  },
  setActiveOption({ commit }, value) {
    commit('UPDATE_ACTIVE_OPTION', value)
  }
}
