import state from './general/state'
import mutations from './general/mutations'
import getters from './general/getters'
import actions from './general/actions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
