import '@s/main.scss'
import Vue from 'vue/dist/vue.js'
import App from '@js/layout/App.vue'
import router from '@js/routes/router'
import VueI18n from 'vue-i18n'
import messages from '@js/i18n/nl_nl.json'
import runtimeConfig from '@jdi/vue-runtime-config'
import store from '@js/store/store'
import VueCurrencyFilter from 'vue-currency-filter'
import locale from 'element-ui/lib/locale'
import lang from 'element-ui/lib/locale/lang/nl'
import { Message, MessageBox, Notification, Loading } from 'element-ui'

Vue.use(runtimeConfig, {
  store,
  dispatchLoad: true // false = default
})

lang.el.colorpicker.confirm = 'Toevoegen aan palet'
locale.use(lang)

Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$message = Message
Vue.prototype.$notify = Notification
Vue.prototype.$loading = Loading.service

Vue.filter('round', (value, decimals, decimalSeperator) => {
  if (!value) {
    return ''
  }
  value = value.toFixed(decimals)
  if (decimalSeperator !== '.') {
    value = value.replace('.', decimalSeperator)
  }
  return value
})

Vue.use(VueCurrencyFilter, {
  symbol: '€',
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true
})

Vue.use(Loading.directive)
Vue.use(VueI18n)

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'nl',
  messages
})

if (process.env.NODE_ENV === 'production') {
  Vue.config.productionTip = false
  Vue.config.devtools = false
  Vue.config.silent = true
  Vue.config.performance = false
} else {
  Vue.config.productionTip = false
  Vue.config.devtools = true
  Vue.config.silent = false
  Vue.config.performance = false
}

/* eslint-disable no-new */
new Vue({
  i18n,
  store,
  router,
  el: '#vueApp',
  components: {
    App
  },
  template: '<App />'
})
