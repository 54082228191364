export default {
  authentication: undefined,
  customer: undefined,
  myTemplates: undefined,
  monkeyTemplates: undefined,
  activeOption: 0,
  preferences: {
    predefinedColors: '#000000,#FFFFFF'
  },
  addressLists: [],
  awareOfAddresslist: false
}
