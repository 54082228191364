export default {
  updateCardSides({ commit }, cardSides) {
    commit('UPDATE_CARD_SIDES', cardSides)
  },
  setCardSide({ commit }, { id, side, data }) {
    if (typeof data === 'string') {
      commit('SET_CARD_SIDE', { id, side, data: JSON.parse(data) })
    } else {
      commit('SET_CARD_SIDE', { id, side, data: data })
    }
  },
  clearCardSides({ commit }) {
    commit('CLEAR_CARD_SIDES')
  },
  updateCardSide({ commit }, { id, side, data }) {
    commit('UPDATE_CARD_SIDE', { id, side, data: JSON.parse(data) })
  },
  updateActiveSideId({ commit }, activeSideId) {
    commit('UPDATE_ACTIVE_SIDE_ID', activeSideId)
  },
  updateActiveObjectIndex({ commit }, index) {
    commit('UPDATE_ACTIVE_OBJECT_INDEX', index)
  },
  toggleGrid({ commit }) {
    commit('TOGGLE_GRID')
  },
  toggleSnap({ commit }) {
    commit('TOGGLE_SNAP')
  }
}
