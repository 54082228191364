<script>
/* eslint-disable */
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
import Notification from 'element-ui/lib/notification'
import { SHOW_ERROR_MESSAGE, ERROR_DURATION } from '@js/constants'

Vue.prototype.$notify = Notification

export default {
  data() {},
  methods: {
    ...mapGetters('account', ['getAuthorizationHeaders']),
    ...mapActions('account', ['clearAuthentication', 'setAuthentication']),
    axiosInstance(intercept = true) {
      const instance = axios.create({
        headers: { Accept: 'application/json', ...this.getAuthorizationHeaders() },
        validateStatus: status => {
          return status >= 200 && status < 300
        }
      })

      instance.interceptors.response.use(
        response => {
          this.setAuthentication({
            tokenType: 'Bearer',
            accessToken: response.headers['x-auth-token']
          })
          return response
        },
        error => {
          if (intercept) {
            if (error.response && error.response.status) {
              if (error.response.status === 400) {
                if (error.response.data === 'errors.session_expired') {
                  this.$store.dispatch('dialog/showDialog', {
                    component: 'SignIn',
                    title: 'Inloggen',
                    description: 'Uw sessie is verlopen, log aub opnieuw in',
                    cancel: () => {
                      this.clearAuthentication()
                      if (SHOW_ERROR_MESSAGE) {
                        this.$notify.error({
                          title: 'U bent niet langer ingelogd',
                          duration: ERROR_DURATION
                        })
                      }
                      this.$router.push('/').catch(() => {})
                    }
                  })
                } else if (error.response.data === 'errors.order_does_not_exist') {
                  if (this.$route.meta.orderRequired) {
                    if (SHOW_ERROR_MESSAGE) {
                      this.$notify.error({
                        title: 'Uw sessie is verlopen',
                        duration: ERROR_DURATION
                      })
                    }
                    this.$router.push('/').catch(() => {})
                  }
                } else {
                  throw Error(error.response.data)
                }
              } else if (error.response.status === 401) {
                this.$store.dispatch('dialog/showDialog', {
                  component: 'SignIn',
                  title: 'Inloggen',
                  description: 'U bent niet ingelogd, log aub in om verder te gaan',
                  cancel: () => {
                    this.clearAuthentication()
                    if (SHOW_ERROR_MESSAGE) {
                      this.$notify.error({
                        title: 'U bent niet langer ingelogd',
                        duration: ERROR_DURATION
                      })
                    }
                    this.$router.push('/').catch(() => {})
                  }
                })
              } else if (error.response.status === 500) {
                return axios.request(error.config) //retry request
              }
            }
          }
          return Promise.reject(error)
        }
      )
      return instance
    }
  }
}
</script>
