export default {
  UPDATE_CONFIG(state, config) {
    state.config = config
  },
  UPDATE_TOOLTIPS(state, tooltips) {
    state.tooltips = tooltips
  },
  UPDATE_SAVING_STATE(state, value) {
    state.saving = value
  },
  UPDATE_ACTIVE_OPTION(state, value) {
    state.activeOption = value
  }
}
