import VueRouter from 'vue-router'
import Vue from 'vue/dist/vue.js'
import routes from './routes'
Vue.use(VueRouter)

// 3. Create the router instance and pass the `routes` option
const router = new VueRouter({
  mode: 'history',
  routes
})
export default router
