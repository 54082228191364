export default {
  UPDATE_CARD_SIDES(state, cardSides) {
    state.cardSides = cardSides
  },
  SET_CARD_SIDE(state, payload) {
    if (state.cardSides.length < 2) {
      state.cardSides.push(payload)
    }
  },
  CLEAR_CARD_SIDES(state) {
    state.cardSides = []
  },
  UPDATE_CARD_SIDE(state, payload) {
    const index = state.cardSides.findIndex(side => payload.side === side.side)
    state.cardSides[index].data = payload.data
  },
  UPDATE_ACTIVE_SIDE_ID(state, payload) {
    state.activeSideId = payload
  },
  UPDATE_ACTIVE_OBJECT_INDEX(state, payload) {
    state.activeObjectIndex = payload
  },
  TOGGLE_GRID(state) {
    state.grid = !state.grid
  },
  TOGGLE_SNAP(state) {
    state.snap = !state.snap
  }
}
