import Vue from 'vue/dist/vue.js'
import Vuex from 'vuex'
import basket from './store.basket'
import account from './store.account'
import generator from './store.generator'
import general from './store.general'
import dialog from './store.dialog'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const vuexLocalStore = new VuexPersistence({
  storage: window.localStorage,
  modules: ['account']
})

const store = new Vuex.Store({
  modules: {
    account,
    basket,
    generator,
    dialog,
    general
  },
  plugins: [vuexLocalStore.plugin]
})

export default store
